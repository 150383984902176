import Banner from '../components/Banner';
import React, { useState, useRef, useEffect } from 'react';
import "../css/Emporia.css";

function Emporia() {
    const [fadeIn, setFadeIn] = useState(false);
    const targetRef = useRef(null); // Ref for smooth scroll
    const handleScroll = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    useEffect(() => {
        setFadeIn(true);
    }, []);


    return (
        <div>
            <div>
                <Banner
                    logo="/images/emporia.png"
                    title="Welcome to Our Site"
                    subtitle="Commericalize, Faster"
                    backgroundImage="/images/emporia-background.jpg"
                />
            </div>
            <div className='emporia-container'>
                <img
                    src="/images/icons/down-arrow.png"
                    className={`homepage-arrow ${fadeIn ? 'fade-in' : ''}`}
                    alt="Continue"
                    onClick={handleScroll}
                />
                <h1 className="emporia-subheading" ref={targetRef}>How it works</h1>

                <div className='emporia-subsection'>
                    <p className="emporia-subsection-p right-margin">Emporia allows users to generate an array of marketing claims from a single query - using state of the art information retrieval technology coupled with large language models, Emporia is able to scan a wide database of publications to generate nuanced claims for your product.</p>
                    <img src='/images/emporiaImages/Emporia_Mockup1.png' className='emporia-subsection-img'/>
                </div>

                <div className='grey-line-unpadded' />

                <div className='emporia-subsection reverse-in-mobile'>
                <img src='/images/emporiaImages/Emporia_Mockup2.png' className='emporia-subsection-img'/>
                    <p className="emporia-subsection-p left-margin">Once a query is entered, a claims matrix is generated, including key metadata such as a citation and relevant key opinion leaders. Using generative AI, users can prompt Emporia in an interactive chat to introduce specific requests into the matrix.</p>
                </div>

                <div className='grey-line-unpadded' />

                <div className='emporia-subsection'>
                    <p className="emporia-subsection-p right-margin">Keep your query history to reference any matrix you have previously generated. Emporia allows you to browse your prior history, making sure great claims don't escape.</p>
                    <img src='/images/emporiaImages/Emporia_Mockup3.png' className='emporia-subsection-img'/>
                </div>
            </div>

        </div>
    )
}

export default Emporia;