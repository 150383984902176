import React, { useState } from 'react';
import "../css/AboutUs.css";
import Banner from '../components/Banner';
import teamMembers from './teamMembers.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const experiences = [
    { logo: '/images/teamLogos/Google.png', name: 'Google' },
    { logo: '/images/teamLogos/Boston.png', name: 'Boston Scientific' },
    { logo: '/images/teamLogos/Deloitte.png', name: 'Deloitte' },
    { logo: '/images/teamLogos/KPMG.png', name: 'KPMG' },
    { logo: '/images/teamLogos/Stanford.png', name: 'Stanford Medicine' },
    { logo: '/images/teamLogos/TOYOTA.png', name: 'Toyota' },
    { logo: '/images/teamLogos/EXADEL.png', name: 'Exadel' },
    { logo: '/images/teamLogos/DEXCOM.png', name: 'Dexcom' },
    { logo: '/images/teamLogos/Moniminy.png', name: 'Montminy & Co' },
    { logo: '/images/teamLogos/walmartLogopng.png', name: 'Walmart' },
    { logo: '/images/teamLogos/Seal.png', name: 'Seal' },
    { logo: '/images/teamLogos/EXACT.png', name: 'Exact Sciences' },
    { logo: '/images/teamLogos/Armanino.png', name: 'Armanino' },
    { logo: '/images/teamLogos/Aerin.png', name: 'Aerin Medical' },
    { logo: '/images/teamLogos/FOX.png', name: 'Fox' },
    { logo: '/images/teamLogos/UCLA_David.png', name: 'UCLA David Geffen School of Medicine' },
    { logo: '/images/teamLogos/UCLA_Health.png', name: 'UCLA Health' },
    { logo: '/images/teamLogos/NewCentury.png', name: 'New Century Capital Partners' },
];

const AboutUs = () => {
    const [selectedMember, setSelectedMember] = useState(null);
    const [showMoreExperience, setShowMoreExperience] = useState(false);

    const handleBioClick = (member) => {
        setSelectedMember(member);
    };

    const handleCloseModal = () => {
        setSelectedMember(null);
    };

    const handleShowMoreExperience = () => {
        setShowMoreExperience((prevShowMore) => !prevShowMore);
    };

    return (
        <div className="about-page">
            <Banner
                title="About Us"
                subtitle="The story of BioStrive began here"
                backgroundVideo="https://dvin3ok3vx.ufs.sh/f/KPXtX1Doo74RKacMdtoo74Rz9Hrxf8QBiusY0mIO5gcX6Ckj"
                backgroundImage="/images/contact-banner-placeholder.jpg"
            />

            <section className="team">
                <div className="team-heading">
                    <h2 className='about-us-h2' >Our Executive Team</h2>
                </div>
                <div className="team-grid">
                    {teamMembers.map((member, index) => (
                                <div
                                    key={index}
                                    className="team-card"
                                    onClick={() => handleBioClick(member)}
                                    role="button"
                                    tabIndex={0} // Makes the card focusable for accessibility
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') handleBioClick(member); // Open modal with keyboard
                                    }}
                                >
                                    <img src={member.image} alt={`${member.name}`} className="team-image" />
                                    <h3>{member.name}</h3>
                                    <p>{member.title}</p>
                                    <button
                                        className="bio-button"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevents the card click from triggering when clicking the button
                                            handleBioClick(member);
                                        }}
                                    >
                                        Biography &#8250;
                                    </button>
                                </div>
                            ))}
                    </div>
            </section>

            {selectedMember && (
                <div className="modal-overlay" onClick={handleCloseModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-button" onClick={handleCloseModal}>×</button>
                        <div className="modal-header">
                            <img src={selectedMember.image} alt={`${selectedMember.name}`} className="modal-image" />
                            <div className="modal-info">
                                <h3>{selectedMember.name}</h3>
                                 <p className="position">{selectedMember.title}</p>
    
                                     {/* Contact Group for LinkedIn and Email */}
                                     <div className="contact-info">
                                        <a href={selectedMember.linkedIn} target="_blank" rel="noopener noreferrer" className="linkedin-link">
                                            <FontAwesomeIcon icon={faLinkedin} style={{ marginRight: '0.5rem', color: 'gray' }} />
                                            View LinkedIn
                                        </a>
                                        
                                        {/* Email Link with Icon */}
                                        <p className="email">
                                            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '0.5rem', color: 'gray' }} />
                                            Email:&nbsp; <a href={`mailto:${selectedMember.email}`}>{selectedMember.email}</a>
                                        </p>
                                    </div>
                            </div>
                        </div>
                        <h4 className='bio-h4'>Biography</h4>
                        <p className="modal-bio">{selectedMember.bio}</p>
                    </div>
                </div>
            )}

            <hr className="section-divider" />

            <section className="experience">
                <div className = "experience-heading">
                    <h2 className='our-team-h2'>Our Team's Experience</h2>
                </div>
                <div className={`experience-grid ${showMoreExperience ? 'expanded' : ''}`}>
                    {experiences.slice(0, showMoreExperience ? experiences.length : 6).map((exp, index) => (
                        <div key={index} className="experience-card">
                            <img src={exp.logo} alt={exp.name} className="experience-logo" />
                        </div>
                    ))}
                </div>
                <button className="show-more" onClick={handleShowMoreExperience}>
                    {showMoreExperience ? 'Hide' : 'Show more'}
                    <span className={`arrow ${showMoreExperience ? 'up' : ''}`}>&#8250;</span>
                </button>
            </section>
        </div>
    );
};

export default AboutUs;
