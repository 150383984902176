import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Header.css';

function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [servicesSubmenuOpen, setServicesSubmenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const toggleServicesSubmenu = () => {
    setServicesSubmenuOpen(!servicesSubmenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    setServicesSubmenuOpen(false);
  };

  return (
    <header>
      <Link to="/">
        <img src="/images/logo-transparent.png" alt="BioStrive Logo" className="header-logo" />
      </Link>
      <nav>
        <ul className={`desktop-menu ${mobileMenuOpen ? 'hidden' : ''}`}>
          <li><Link to="/">Home</Link></li>
          <li
            className="dropdown-container"
            onMouseEnter={() => setServicesSubmenuOpen(true)}
            onMouseLeave={() => setServicesSubmenuOpen(false)}
          >
            <button className="dropdown-button dropdown-link">Services</button>
            {servicesSubmenuOpen && (
              <ul className="dropdown">
                <li>
                  <NavLink to="/market-access" className="dropdown-link">
                    Market Access & Evidence Generation
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/technology" className="dropdown-link">
                    Technology
                  </NavLink>
                </li>
                {/*<li>*/}
                {/*  <NavLink to="/MarketAndBD" className="dropdown-link">*/}
                {/*    Marketing & Business Development*/}
                {/*  </NavLink>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <NavLink to="/talent" className="dropdown-link">*/}
                {/*    Internship & Talent Acquisition*/}
                {/*  </NavLink>*/}
                {/*</li>*/}
              </ul>
            )}
          </li>
          <li><Link to="/about-us">About</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>




        <button className="hamburger" onClick={toggleMobileMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>


        {mobileMenuOpen && (
          <div className='mobile-menu-container'>
            <div className="mobile-menu">
              <button className='close-mobile-menu' onClick={closeMobileMenu}>X</button>
              <ul>
                <li><Link to="/" onClick={closeMobileMenu}>Home</Link></li>
                <li>
                  <button className="mobile-dropdown-button" onClick={toggleServicesSubmenu}>
                    Services
                  </button>
                  {servicesSubmenuOpen && (
                    <ul className="mobile-submenu">
                      <li>
                        <NavLink to="/market-access" onClick={closeMobileMenu}>
                          Market Access & Evidence Generation
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/technology" onClick={closeMobileMenu}>
                          Technology
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/MarketAndBD" onClick={closeMobileMenu}>
                          Marketing & Business Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/talent" onClick={closeMobileMenu}>
                          Internship & Talent Acquisition
                        </NavLink>
                      </li> */}
                    </ul>
                  )}
                </li>
                <li><Link to="/about-us" onClick={closeMobileMenu}>About</Link></li>
                <li><Link to="/contact" onClick={closeMobileMenu}>Contact</Link></li>
              </ul>
            </div>
            <div className='mobile-menu-lower' />
          </div>
        )}
      </nav>
    </header>
  );
}

export default Header;
