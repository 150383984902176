import React, { useState, useEffect, useRef } from 'react';
import "../css/Home.css";
import Banner from '../components/Banner';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";
// import { NavLink } from 'react-router-dom';

function Home() {
  const [fadeIn, setFadeIn] = useState(false);
  const [expand, setExpand] = useState(false); // State to track expansion
  const targetRef = useRef(null); // Ref for smooth scroll
  const emporiaRef = useRef(null); // Ref for IntersectionObserver
  const navigate = useNavigate();

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  function openEmporia () {
    window.scrollTo(0,0);
    navigate('/emporia');
  }

  useEffect(() => {
    setFadeIn(true); // Fade-in effect for the arrow

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setExpand(true); // Trigger expansion when in view
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (emporiaRef.current) {
      observer.observe(emporiaRef.current);
    }

    return () => {
      if (emporiaRef.current) {
        observer.unobserve(emporiaRef.current);
      }
    };
  }, []);

  return (
    <>
      <div>
        <Banner
          logo="/images/logo-transparent.png"
          title="Welcome to Our Site"
          subtitle="Innovation That Transforms Lives"
          backgroundVideo="https://dvin3ok3vx.ufs.sh/f/KPXtX1Doo74R6Lel6g1C9R7pFdMLvoPzs3yUVc5HKtkaY2nE"
          backgroundImage="/images/contact-banner-placeholder.jpg"
        />
      </div>
      <div className="homepage-container">
        <img
          src="/images/icons/down-arrow.png"
          className={`homepage-arrow ${fadeIn ? 'fade-in' : ''}`}
          alt="Continue"
          onClick={handleScroll}
        />
        <div className="homepage-paragraph-container">
          <h1 className="homepage-header" ref={targetRef}>Our Mission</h1>
          <p className="ourmission-p">
            BioStrive specializes in propelling growth-stage BioTech, MedTech, and life science companies towards success through commercialization, data analysis, market access, and research. Our team combines industry insights and experience to deliver tailored business solutions that meet the unique needs of our clients. We focus on maximizing market impact, unlocking actionable development opportunities, and advancing scientific research. We are committed to bridging the gap between technological innovation and patient care, shaping the future of healthcare and improving lives.
          </p>
        </div>
        <h1 className="homepage-header">Coming Soon...</h1>
      </div>
      <div
        ref={emporiaRef}
        className={`homepage-emporia-container ${expand ? 'expand-width' : ''}`}
      >
        <div className="emporia-logo-con">
          <img
            src="/images/emporia.png"
            alt="emp-logo"
            className="emporia-main-logo"
          />
          <p className="emporia-description">
            Generative AI that builds claim matrices for pharmaceutical and MedTech companies
          </p>
        </div>
        <hr className="page-break-home" />
        <div className="what-is-emporia-con">
          <h1 className="what-emporia">What is Emporia?</h1>
          <p className="what-is-desc">
            Emporia is a generative AI platform designed to automate the process of marketing claim generation for life science companies. With Emporia, users simply attach any source document, and our advanced AI model generates a comprehensive set of marketing claims, organized into distinct categories. Emporia empowers marketing teams to save valuable time and resources, allowing them to focus on higher-level strategic initiatives, accelerating innovation within the life sciences sector.
          </p>
        </div>
        <hr className="page-break-home" />
        <div className="marketing-made-simple-con">
          <h1 className="what-emporia">Marketing Made Simple</h1>
          <div className="whites-boxes-con">
            <div className="indv-white-con">
              <div className="text-container">
                <h1 className="white-box-h1">Data Security</h1>
                <p className="white-boxes-p">
                  Emporia ensures data security by dynamically retrieving figures without embedding sensitive information into the model.
                </p>
              </div>
            </div>
            <div className="indv-white-con">
              <div className="text-container">
                <h1 className="white-box-h1">Knowledge</h1>
                <p className="white-boxes-p">
                  Emporia leverages information derived from a specialized dataset of commercial claims and publications by leading companies in the life sciences industry.
                </p>
              </div>
            </div>
            <div className="indv-white-con">
              <div className="text-container">
                <h1 className="white-box-h1">Value Brief</h1>
                <p className="white-boxes-p">
                  Emporia provides tables of claims that empower users to learn about the industry and products and refine marketing efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="page-break-home" />
      </div>
      <div className="click-to-see-con">
       <button className="click-btn" onClick={openEmporia}>Click to see how Emporia functions <IoIosArrowForward /></button>
       <img
         src="/images/emporia-macbook.png"
         alt="emp-logo"
         className="emporia-mac-logo"
       />
      </div>
      {/*<div className="get-a-demo">*/}
      {/*  /!* <hr className="page-break-home" /> *!/*/}
      {/*  <h1 className="get-a-h1">Get a Demo to Learn More Now</h1>*/}
      {/*  <button className="get-in-touch"*/}
      {/*    onClick={() =>*/}
      {/*      navigate('/contact')*/}
      {/*    }>Get a Demo</button>*/}
      {/*</div> */}
      <div className="homepage-container">
        <div className="homepage-publications-container">
          <h1 className="homepage-header">Recent Publications</h1>
          {/* <div
            className="homepage-publications-box ourmission-p"
            onClick={() =>
              window.open(
                'https://www.linkedin.com/company/biostrive/posts/?feedView=articles',
                '_blank'
              )
            }
          >
            <div className='homeapge-publications-tinted'>
              <p>Follow our LinkedIn page</p>
            </div>
          </div> */}
          <div 
          className='homepage-linkedin-container'
          onClick={() =>
            window.open(
              'https://www.linkedin.com/company/biostrive/posts/?feedView=articles',
              '_blank'
            )
          }
          >
            <div className='homepage-linkedin-inner'>
              <p>Click to read our publications {`>`}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;